import React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'
import { AddOrModifyLink, GetLink } from "../../middlewares/api";
import CircularIndeterminate from '../Animation/Loading';
import { Container, Stack, Box, Typography, TextField, Button } from '@mui/material';

export const CreateLinkForm = ({ match }) => {
    const navigate = useNavigate();
    
    // Get path parameter
    const { id } = useParams();
    const isAddMode = !id;

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState: { errors , isSubmitting, isSubmitSuccessful }
    } = useForm({ 
        defaultValues: {
            title: "", 
            def_url: "",
            yahoo_patrol_url: ""
        }
    });

    const onSubmit = async (data) => {
        try {
            let result;
            if (isAddMode) {
                result = await AddOrModifyLink(null, data.title, data.def_url, data.yahoo_patrol_url);
            } else {
                result = await AddOrModifyLink(id, data.title, data.def_url, data.yahoo_patrol_url);
            }
            
            navigate("/links");

        } catch (err) {
            setError("linkFormError", {
                type: "manual",
                message: err?.message,
            });
        }
            
    };

    const submitAction = (e) => {
        e.preventDefault();       // リロードの防止
        clearErrors("linkFormError");
        handleSubmit(onSubmit)();
    };

    const GetLinkSetField = async (id) => {
      const res = GetLink(id);
      const data = await res;
      const fields = ["title", "def_url", "yahoo_patrol_url"];
      fields.forEach((field) => setValue(field, data[field]));
      return res;
    }

    const { data, isFetching, isError, error } = useQuery({
      queryKey: ['link', id],
      queryFn: () => GetLinkSetField(id),
      enabled: !isAddMode,             // Add ModeがTrueの時だけ実行
      staleTime: 0,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    });


    if (isFetching) {       // ちなみにisLoadingの場合は初回のみになってしまう
      return <CircularIndeterminate />

    } else {
      return (
          <Container maxWidth="sm" sx={{ pt: 5 }}>
            <Typography mb={2}
              variant="h4"
            >
              リンク作成
            </Typography>
            <Stack spacing={2}>
              <TextField
                label="Title"
                {...register('title', { required: true })}
                size="small"
              />
              <Box color="error.main" fontSize={12}>
                <ErrorMessage
                  errors={errors}
                  name="title"
                  as="p"
                  message="⚠ タイトルを入力してください"
                />
              </Box>
              <TextField
                label="Default Jump URL"
                {...register('def_url', { required: true })}
                size="small"
              />
              <Box color="error.main" fontSize={12}>
                <ErrorMessage
                  errors={errors}
                  name="def_url"
                  as="p"
                  message="⚠ デフォルトのURLを入力してください"
                />
              </Box>
              <TextField
                label="Yahoo Jump URL"
                {...register('yahoo_patrol_url', { required: true })}
                size="small"
              />
              <Box color="error.main" fontSize={12}>
                <ErrorMessage
                  errors={errors}
                  name="yahoo_patrol_url"
                  as="p"
                  message="⚠ Yahoo用のURLを入力してください"
                />
              </Box>
              <Box color="error.main" fontSize={12}>
                <ErrorMessage
                  errors={errors}
                  name="linkFormError"
                  render={({ message }) => <p>{message}</p>}
                />
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={isSubmitting || isSubmitSuccessful}
                  onClick={(e) => submitAction(e)}
                >
                  {isAddMode ? "作成" : "更新"}
                </Button>
              </Box>
            </Stack>
          </Container>
        );
    }
}
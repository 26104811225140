import logo from './logo.svg';
import './App.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Container from '@mui/material/Container';
import Base from "./components/base";

function App() {
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Container
          disableGutters
          maxWidth="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Base />
        </Container>
      </QueryClientProvider>
    </>
  );
}

export default App;
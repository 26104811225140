import { useState, useEffect } from "react";
import { VerifyAccess } from "../../middlewares/api";

export const CheckAuthPage = () => {
    const [ authStatus, setAuthStatus ] = useState(null);

    useEffect(() => {
        async function checkAuthStatus() {
          try {
            const result = await VerifyAccess();
            let message = (result.ok === true) ? "Logined!" : "Not Logined..."
            setAuthStatus(message);

          } catch (error) {
            // エラーハンドリング
            console.error('データの取得に失敗しました:', error);
          }
        }
    
        // コンポーネントがマウントされた後にデータの取得を開始
        checkAuthStatus();
      }, []); // 空の依存配列を渡すことで一度だけ実行されます

    return (
        <div>
            <p>You are {authStatus}</p>
        </div>
    );
};
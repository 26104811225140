import React, { useState, createContext } from "react";

export const AuthContext = createContext({
	isAuthorized: false,
	setIsAuthorized: () => {},
	userEmail: "",
	setUserEmail: () => {},
});

export const AuthProvider = ({ children }) => {
    const [isAuthorized, setIsAuthorized] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    return (
        <AuthContext.Provider value={{isAuthorized, setIsAuthorized, userEmail, setUserEmail}}>
            {children}
        </AuthContext.Provider>
    )
}
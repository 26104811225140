import { Button, Snackbar } from '@mui/material'
import { useState } from 'react'
import CopyIcon from '@mui/icons-material/ContentPaste';

const CopyToClipboardButton = ({buttonText, copyText, promptMessage}) => {      // {}で展開しないと直接使えない(例えばbuttonTextはprop.buttonTextとして渡される)
    const [open, setOpen] = useState(false)
    const handleClick = () => {
      setOpen(true);
      navigator.clipboard.writeText(copyText);
    }
    
    return (
        <>
            <Button
                variant="contained"
                startIcon={<CopyIcon />}
                onClick={handleClick}
            >
                {buttonText}
            </Button>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message={promptMessage}
            />
        </>
    )
}

export default CopyToClipboardButton
import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Login } from "../../middlewares/api";
import { AuthContext } from "../globalContext";

export const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Use global context
  const { isAuthorized, setIsAuthorized } = useContext(AuthContext);

  const [ returnTo, setReturnTo ] = useState(location.state?.returnTo);

  const {
    register, 
    handleSubmit, 
    setError, 
    clearErrors,
    formState: { errors }
  } = useForm({ 
    defaultValues: {
      email: "", 
      password: ""
    }
  });

  const onSubmit = async (data) => {
    try {
      const result = await Login(data);
      if (result.ok === true) {
        //console.log('ログイン成功');
        setIsAuthorized(true);
        navigate(returnTo ?? "/");

      } else {
        setError('loginError', {
          type: 'manual',
          message: result.error?.message, // ログインエラーメッセージをセット
        });
        console.error('ログインエラー:', result.error?.message);
      }

    } catch (error) {
      setError('loginError', {
        type: 'manual',
        message: error?.message,
      });
      console.error('ログインエラー:', error);
    }
  };

  const submitAction = (e) => {
    e.preventDefault();       // リロードの防止
    clearErrors("loginError");
    handleSubmit(onSubmit)();
  };


  return (
    <div>
      <h2>ログイン</h2>
      <form onSubmit={(e) => {submitAction(e)}}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            {...register('email', { required: true })}
          />
          {errors.email && <span>Emailは必須です</span>}
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            {...register('password', { required: true })}
          />
          {errors.password && <span>Passwordは必須です</span>}
        </div>
        {errors.loginError && <div className="error">{errors.loginError.message}</div>}
        <button type="submit">ログイン</button>
      </form>
    </div>
  );
}

import React, { useEffect, useState, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { VerifyAccess } from "../middlewares/api";

import { AuthContext } from "../components/globalContext";

export default function ProtectedRoutes() {
    // Use global context
    const { isAuthorized, setIsAuthorized } = useContext(AuthContext);

    useEffect(() => {
        async function verifyAccess() {
          try {
            // ここで非同期操作を実行し、認証を検証
            const result = await VerifyAccess();
    
            if (result.ok) {
              setIsAuthorized(true);
            } else {
              setIsAuthorized(false);
            }
          } catch (error) {
            console.error("認証エラー:", error);
            setIsAuthorized(false);
          }
        }
    
        verifyAccess();
    }, []);

    if (isAuthorized === null) {
        // 非同期操作が完了するまでローディングを表示
        return <div>Loading...</div>;
    }

    if (isAuthorized) {
      return (
        <Outlet />
      )

    } else {
      const returnTo = { returnTo: window.location.pathname };
      return <Navigate to="/login" state={returnTo} />
    }
};
import React, { useState, createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "../routes/protectedRoutes";

import { TopPage } from "./TopPage/index";
import { LoginForm } from "./LoginPage/index";
import { CheckAuthPage } from "./CheckAuthPage/index";
import { CreateLinkForm } from "./CreateLinkPage/index";
import { ListLinkPage } from "./ListLinkPage/index";
import Navbar from "./Navbar/index";
import Footer from "./Footer/index";
import UserDetail from "./UserDetail";

import { AuthProvider } from "./globalContext";

import Container from '@mui/material/Container';

export default function BasePage() {
    return (
		<>
			<AuthProvider>
				<Navbar />
				<Container component="main" sx={{ flexGrow: 1 }}>
					<Routes>
						<Route path={`/`} element={<TopPage />} />
						<Route path={`/login`} element={<LoginForm />} />
						<Route path={`/checkauth`} element={<CheckAuthPage />} />
						<Route element={<ProtectedRoutes />}>
							<Route path={`/userdetail`} element={<UserDetail />} />
							<Route path={`/form`} element={<CreateLinkForm />} />
							<Route path={`/form/:id`} element={<CreateLinkForm />} />
							<Route path={`/links`} element={<ListLinkPage />} />
						</Route>
					</Routes>
				</Container>
			</AuthProvider>
			<Footer />
		</>
    )
}
import axios from "axios";
axios.defaults.withCredentials = true;      // if true, axios send cookies

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_BASE_URL,
    timeout: 3000,
});

instance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        if (error.response) {
            return Promise.reject(
                error.response.data.error
            //     {
            //     code: error.response.statusCode, 
            //     name: error.response.data?.error?.statusMessage || "ResponseError",
            //     message: error.response.data?.error?.message || "API server returned an error.",
            // }
            );

        } else if (error.request) {
            return Promise.reject({
                name: "RequestError",
                message: error.message || "Unable to send request."
            });

        } else {
            return Promise.reject({
                name: "UnkownError",
                message: error.message || "Unknown error occurred."
            });
        }
    }
);


export const Login = async (data) => {
    try {
        const result = instance.post("/user/login", data)
            .then(() => {
                // Code 200s returned
                return { ok: true };
            })
            .catch((error) => {
                // Code 400s or 500s returend
                return {
                    ok: false,
                    error: {
                        name: error?.name || "UnkownError",
                        message: error?.message || "",
                    }
                };
            });

        return result;

    } catch (error) {
        return {
            ok: false,
            error: {
                name: error?.name || "UnkownError",
                message: error?.message || "",
            }
        };
    }
};


export const Logout = async () => {
    try {
        const result = await instance.post("/user/logout")
            .then(() => {
                return Promise.resolve({ ok: true });
            })
            .catch((error) => {
                return Promise.reject(new Error(error));
            });

        return result;

    } catch (err) {
        return { ok: false, message: err.message };
    }
};


export const VerifyAccess = async () => {
    try {
        const authResult = await instance.post("/auth/verify")
            .then(() => {
                return { ok: true };
            })
            .catch((error) => {
                return { ok: false, statusCode: error.statusCode };
            });

        if (authResult.ok === true) {
            return authResult;

        } else if (authResult.statusCode === 401) {
            const refreshResult = await instance.post("/auth/refresh")
                .then(() => {
                    return { ok: true };
                })
                .catch((error) => {
                    return { ok: false, message: error.message };
                });

            return refreshResult;

        } else {
            throw new Error("Unknown Error");
        }

    } catch (err) {
        return { ok: false, message: err.message };
    }
};


// export const CreateLink = async (title, def_url, yahoo_url, retryLimit = 1) => {
//     try {
//         const data = {
//             title: title,
//             def_jump_url: def_url,
//             yahoo_patrol_jump_url: yahoo_url,
//         }

//         return instance.post("/manage/create", data)
//             .then((res) => {
//                 return Promise.resolve(res);
//             })
//             .catch((err) => {
//                 if ((err.statusCode === 401) && (retryLimit > 0)) {
//                     VerifyAccess();
//                     const retryResult = CreateLink(title, def_url, yahoo_url, 0);
//                     return retryResult;

//                 } else {
//                     return Promise.reject(err);
//                 }
//             });

//     } catch (err) {
//         return Promise.reject(err);
//     }
// };


export const AddOrModifyLink = async (id, title, def_url, yahoo_url) => {
    try {
        const data = {
            "title": title,
            "def_jump_url": def_url,
            "yahoo_patrol_jump_url": yahoo_url,
        }

        let result;
        if (id) {
            result = await instance.patch(`/links/${id}`, data);
        } else {
            result = await instance.post("/links", data);
        }

        return Promise.resolve(result);
    
    } catch (err) {
        return Promise.reject(err);
    }
}


export const GetListLinks = async (cursor, retryLimit = 1) => {
    try {
        let params = {};
        if (cursor === null) {
            return {};
        } else if (cursor !== 0) {
            params["cursor"] = cursor;
        }

        const data = await instance.get("/links/", { params: params });
        return data;

    } catch (err) {
        // Retry only once if unauthorized
        if ((err.statusCode === 401) && (retryLimit > 0)) {
            await VerifyAccess();
            const retryResult = await GetLink(cursor, 0);
            return retryResult;
        }

        // Other error
        return err;
    }
};


export const GetLink = (id) => {
    if (!id) {
        throw new Error("ID is required");
    }

    return instance.get(`/links/${id}`);
};
import {
  Container,
  Grid,
  Box,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

export default function Footer() {
  return (
    <>
      <Container maxWidth="100%" component="footer" sx={{ flexGrow: 0 }}>
        <Box sx={{ borderTop: 1, mt: 4, mb: 2 }}></Box>
        <Box sx={{ justifyContent: "center" }}>
          <FormControl size="small">
            <InputLabel id="demo-simple-select-label">Language</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Language"
            >
              <MenuItem value={"EN"}>English</MenuItem>
              <MenuItem value={"JP"}>Japanese</MenuItem>
              <MenuItem value={"CN"}>Chinese</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            my: 1,
          }}
        >
          <div>
            <Typography variant="caption" color="initial">
              Hello
            </Typography>
          </div>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            mb: 2,
          }}
        >
          <Typography variant="caption" color="initial">
            Copyright ©2022. [] Limited
          </Typography>
        </Box>
      </Container>
      <style>
        {`
          footer {
            position: sticky;
          }
        `}
      </style>
    </>
  );
}

import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import { GetListLinks } from "../../middlewares/api";
import axios from "axios";

import { 
	Button, 
	Table, 
	TableBody, 
	TableCell, 
	TableContainer, 
	TableHead, 
	TableRow, 
	Paper, 
	Box, 
	Grid, 
	Typography,
	Toolbar,
	InputBase,
} from '@mui/material';

import { Edit, AddCircleOutlined } from '@mui/icons-material';
import CopyToClipboardButton from '../Buttons/CopyToClipboardButton';


// Create a client
const queryClient = new QueryClient();

export const ListLinkPage = () => {
    return (
        // Provide the client to your App
        <QueryClientProvider client={queryClient}>
            <LinksTable />
        </QueryClientProvider>
    )
}

function LinksTable() {
    // Access the client
    const queryClient = useQueryClient();
    //const query = useQuery({ queryKey: ['links'], queryFn: GetListLinks });

	// Setup navigation
	const navigate = useNavigate();

	const apiCaller = async (pagenationData) => {
		const cursor = pagenationData.pageParam;
		const data = GetListLinks(cursor);
		return data;
	}

    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ["links"],
        queryFn: apiCaller,
		initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
			return lastPage.hasNext ? lastPage.links[lastPage.links.length - 1].id : null;
        },
    });

    const memorizedData = useMemo(() => isLoading ? [] : data.pages.map((elem) => elem.links).flat().filter((item) => item != null), [isLoading, data]);

	const handleAddButtonClick = () => {
		navigate("/form");
	};

    const handleEditButtonClick = (id) => {
		navigate(`/form/${id}`);
    };

    const columns = [
        { field: "id", name: "ID", align: "left", width: 70 },
        { field: "title", name: "Title", align: "left", width: 70 },
        { field: "copy", name: "Copy Link", align: "center", width: 50 },
        { field: "edit", name: "Edit", align: "center", width: 50 },
    ];

    return (
        <>
			<Toolbar>
				<InputBase
					sx={{ mr: 1, flex: 1 }}
					placeholder="Search…"
					inputProps={{ 'aria-label': 'search' }}
				/>
				<Button
					startIcon={<AddCircleOutlined />}
					variant="contained"
					onClick={() => handleAddButtonClick()}
				>
					Add Link
				</Button>
			</Toolbar>

			<Box sx={{ mt: 2 }}>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="link table">
						<TableHead>
							<TableRow>
								{columns.map((header, index) => (
									<TableCell
										key={index}
										align={header.align}
									>
										{header.name}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						{memorizedData && (
							<TableBody>
								{memorizedData.map((row) => (
									<TableRow
										key={row.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row">{row.id}</TableCell>
										<TableCell align="left">{row.title}</TableCell>
										<TableCell align="center">
											<CopyToClipboardButton
												buttonText="Copy"
												// copyText={row.url}
												copyText={process.env.REACT_APP_BACKEND_API_BASE_URL + "/pub/" + row.id}
												promptMessage="Your link copied!"
											/>
										</TableCell>
										<TableCell align="center">
											<Button 
												variant="contained"
												startIcon={<Edit />}
												onClick={() => handleEditButtonClick(row.id)}
											>
												Edit
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						)}
					</Table>
				</TableContainer>
			</Box>
			<Box sx={{ mt: 1 }}>
				<Button
					variant="contained"
					onClick={() => fetchNextPage()}
					disabled={!hasNextPage || isFetchingNextPage}
				>
					{isFetchingNextPage
						? 'Loading more...'
						: hasNextPage
						? 'Load More'
						: 'Nothing more to load'}
				</Button>
			</Box>
        </>
    );
}
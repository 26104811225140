import React from "react";
import axios from "axios";

const baseURL = "http://194.36.208.181:3000";

export default function UserDetail() {
    const [post, setPost] = React.useState(null);

    React.useEffect(() => {
        axios.get(baseURL + "/test/dummydata", {
            // params: {
            //     email: "test10@testmail.com",
            // }
        }).then((response) => {
            setPost(response.data);
        });
    }, []);

    if (!post) return null;

    return (
        <div>
          <h1>{post.id}</h1>
          <p>{post.name}</p>
        </div>
      );

}